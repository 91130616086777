var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('ax-table',{ref:"roleTable",attrs:{"show-search":true,"searchForm":_vm.searchForm,"toolActions":_vm.toolActions,"searchActions":_vm.searchActions,"columns":_vm.columns,"dataSourceApi":_vm.api.list,"dataSourceParams":_vm.dataSourceParams,"clickable":false,"showToolBar":true,"rowSelection":{
      selectedRowKeys: _vm.selectedRowKeys,
      onChange: _vm.onSelectChange,
    },"dynamicSlotNames":['gridId', 'gridId'],"scroll":{ y: '45vh', x: '80vw' }},on:{"action-column-click":_vm.actionColumnClick,"bulkDeletion":_vm.deleteSecurityPersonnel,"add":_vm.add,"export":_vm.expor,"exportAll":_vm.exportAll,"reset":_vm.reset,"searchlist":_vm.searchlist,"pop-confirm":_vm.popConfirm},scopedSlots:_vm._u([{key:"state",fn:function({ record }){return _c('div',{},[_c('a-button',{class:{
          yellow: record.state === '待生效',
          green: record.state === '生效中',
          grey: record.state === '已失效',
        }},[_vm._v(" "+_vm._s(record.state === "待生效" ? "待生效" : record.state === "生效中" ? "生效中" : "已失效")+" ")])],1)}}])},[_c('div',{staticStyle:{"display":"flex"},attrs:{"slot":"gridId"},slot:"gridId"},[_c('a-cascader',{attrs:{"options":_vm.options,"change-on-select":"","placeholder":"请选择所属网格","show-search":{ filter: _vm.filter },"getPopupContainer":(triggerNode) => triggerNode.parentNode,"z-index":666,"model":_vm.cuurentAreaCode},on:{"change":_vm.onChange},model:{value:(_vm.cuurentAreaCode1),callback:function ($$v) {_vm.cuurentAreaCode1=$$v},expression:"cuurentAreaCode1"}})],1)]),_c('el-dialog',{attrs:{"title":"新增居民规约","visible":_vm.dialogAdd,"width":"60%","z-index":12,"destroy-on-close":true,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogAdd=$event}}},[_c('addPage',{on:{"closeDialog":function($event){_vm.dialogAdd = false},"refreshTable":_vm.refreshTable}})],1),_c('addedit',{ref:"edit",on:{"refreshTable":_vm.refreshTable}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }